body,html {
  margin: 0;
  padding: 0;
  overflow-y: auto !important;
}
.sectionTransition-enter {
  opacity: 0.01;
  transform: translateX(100px); }

.sectionTransition-enter.sectionTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 200ms ease-in-out; }

.sectionTransition-exit {
  transform: translateX(0);
  opacity: 1; }

.sectionTransition-exit.sectionTransition-exit-active {
  transform: translateX(-100px);
  opacity: 0.01;
  transition: all 200ms ease-in-out; }

.alert-enter {
  opacity: 0;
  transform: scale(0.9); }

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms; }

.alert-exit {
  opacity: 1; }

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms; }

.checklist-position {
  position: absolute;
  bottom: 115px;
  right: 0px;
  z-index: 10; }

.menu-primary-enter {
  display: "none"; }

.menu-primary-enter-active {
  animation-fill-mode: forwards;
  animation-name: move;
  animation-duration: 0.2s;
  animation-timing-function: ease; }

.menu-primary-exit {
  position: absolute; }

.menu-primary-exit-active {
  animation-fill-mode: forwards;
  animation-direction: reverse;
  animation-name: move;
  animation-duration: 0.2s;
  animation-timing-function: ease; }

@keyframes move {
  from {
    transform: translateX(10px);
    opacity: 0; }
  to {
    transform: translateX(0px);
    opacity: 1; } }

@media only screen and (min-width: 1440px) {
  .checklist-position {
    bottom: 220px; } }

@media only screen and (min-width: 1620px) {
  .checklist-position {
    bottom: 290px; } }

.custom-toast-container div {
  z-index: 99999; }

/* Placement of the assistance chat button */
.salesforce-iframe {
  bottom: 0;
  position: absolute;
  right: 32px;
  z-index: 10; }

/* tracking page */
.tracking-logo {
  padding-bottom: 96px; }

.tracking-img {
  height: 100vh;
  max-width: fit-content; }

.searchBarPicker {
  width: 100%;
  border-top-style: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  max-height: 400px;
  overflow-y: scroll;
  top: 10; }

.feedback-emoji {
  border-width: 1px;
  border-style: solid;
  border-color: #b8bdbf;
  border-radius: 50%;
  height: 32px;
  font-size: 25px;
  box-shadow: 0px 3px 8px -1px #a5a5a5;
  transform: translateX(10px); }

.feedback-button {
  margin: 0.3em;
  border: none;
  border-radius: 50%; }

.feedback-button:hover {
  outline-color: #dee3e7;
  opacity: 0.8;
  cursor: pointer;
  transform: scale(1.1); }

[aria-label][data-custom-font]:after {
  content: attr(aria-label);
  font-size: 0.7em;
  font-weight: normal;
  color: #fff; }

.collapsible-zone.open {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.75s ease-in, opacity 0.45s ease-in;
  overflow: ""; }

.collapsible-zone.close {
  max-height: 0px;
  opacity: 0.5;
  transition: max-height 0.65s ease-out, opacity 0.45s ease-out;
  overflow: hidden; }

.min-vh-100 {
  min-height: 100vh !important; }

.hero-loader .level.mb-0.mrc--hero-profile_button {
  right: -46%;
  top: 90px; }

.select-pos-rules .mrc--select .mrc--panel {
  width: 100%; }

@media only screen and (max-width: 1023px) {
  .tracking-img {
    width: 100vw;
    height: auto;
    max-width: none; }
  .tracking-logo {
    padding-bottom: 16px; } }
